









































import { Vue, Component, Prop } from 'vue-property-decorator';

import Accordion from '@/components/Accordion/index.vue';
import SimulatorResultContent from '../SimulatorResultContent/SimulatorResultContent.vue';

import { GroupVacancy } from '../interface/ISimulatorAccordion';

@Component({
  components: {
    Accordion,
    SimulatorResultContent,
  },
})
export default class SimulatorResultAccordion extends Vue {
  @Prop({ required: true }) simulatorResult!: GroupVacancy;

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }
}
