












































































import {
  Vue, Prop, Component, Watch,
} from 'vue-property-decorator';

import CourseWeight from '../CourseWeight/CourseWeight.vue';
import Arrow from '@/assets/icons/Arrow.vue';
import Bullets from '@/assets/icons/Bullets.vue';

import FeedbackUser from '@/components/FeedbackUser/index.vue';

import { Vacancy } from '../interface/ISimulatorAccordion';
import { CardScore } from '../interface/ISisuScore';

const BREAKPOINT_MOBILE = 800;

@Component({
  components: {
    CourseWeight,
    Arrow,
    Bullets,
    FeedbackUser,
  },
  filters: {
    toFixedScore(value: number | string) {
      return Number(value).toFixed(2);
    },
  },
})
export default class SimulatorResultContent extends Vue {
  @Prop({ required: true }) listVacancy!: Array<Vacancy>;

  private isMobile = false;
  private newListVacancy: Array<Vacancy> = [];

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  get averageScore() {
    const { optionsSimulator } = this.$store.getters;

    const total = optionsSimulator.score.reduce((acc: number, simulatorScore: CardScore) => acc + Number(simulatorScore.score), 0);

    return total / optionsSimulator.score.length;
  }

  @Watch('listVacancy', {
    immediate: true,
  })
  setListVacancy() {
    this.newListVacancy = this.listVacancy.map((vacancy: Vacancy) => ({
      ...vacancy,
      isActiveArrow: false,
    }));
  }

  @Watch('$responsive.contentwidth')
  setIsMobile() {
    this.isMobile = window.screen.width < BREAKPOINT_MOBILE;
  }

  toggleArrow(indexVacancy: number) {
    this.newListVacancy = this.newListVacancy.map((vacancy: any, index: number) => {
      if (index === indexVacancy) {
        return {
          ...vacancy,
          isActiveArrow: !vacancy.isActiveArrow,
        };
      }

      return vacancy;
    });
  }
}
