
























import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';
import AccordionLoading from '@/components/AccordionLoading/index.vue';

@Component({
  components: {
    ExLoading,
    AccordionLoading,
  },
})
export default class SimulatorResultLoading extends Vue {
}
